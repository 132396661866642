import PropTypes from 'prop-types';
import { components } from 'react-select';
import Label from '@/storychief/components/Label';

const propTypes = {
  data: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.number,
  }).isRequired,
  children: PropTypes.node.isRequired,
};
const defaultProps = {};

function SelectColorSingleValue(props) {
  return (
    <components.SingleValue {...props}>
      {props.data.color ? (
        <Label variant={`color-coding--${props.data.color}`}>{props.children}</Label>
      ) : (
        props.children
      )}
    </components.SingleValue>
  );
}

SelectColorSingleValue.propTypes = propTypes;
SelectColorSingleValue.defaultProps = defaultProps;

export default SelectColorSingleValue;
